import { gql } from '@apollo/client';

const CLUBS_QUERY = gql`
  {
    clubs(first: 100) {
      nodes {
        categories {
          nodes {
            name
          }
        }
        title
        clubFields {
          clubId
          showOnMakechip
          link
          clubImage {
            sourceUrl
          }
          countryImg {
            sourceUrl
          }
          info {
            union
            rakenack
            fieldGroupName
            course
            country
            rbRakeback
          }
        }
      }
    }
  }
`;

export default CLUBS_QUERY;
