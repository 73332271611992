import axios from 'axios';

const confirmEmail = (userId, userEmail) => {
  return axios.post('http://admin.makechip.club/auth/email/', {
    userId: userId,
    email: userEmail
  });
};

const userVerification = (userEmail, code) => {
  return axios.post('http://admin.makechip.club/auth/token/', {
    email: userEmail,
    token: code
  });
};

const initPayment = (value, currency, clubId, userId, token, platform, chips) => {
  return axios.post(
    'http://admin.makechip.club/pay/payments/',
    {
      amount: value,
      currency: currency,
      club_id: clubId,
      us_game: userId,
      platform: platform,
      chips: chips
    },
    {
      headers: {
        Authorization: `token ${token}`
      }
    }
  );
};

const getCourenccyes = () => {
  return axios.get(
    'https://makechip.herokuapp.com/pay/currency/'
  );
};

const hello = (data) => {
  return axios.post(
    'https://makechip.herokuapp.com/pay/hello/',
    {
      data: data
    }
  );
};

export { confirmEmail, userVerification, initPayment, getCourenccyes, hello };
