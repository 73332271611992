import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['en', 'ru'];
const languageDetector = () => {
  const lang = navigator.language || navigator.userLanguage;
  if (lang == 'ru' || lang == 'uk') {
    return 'ru';
  } else {
    return 'en';
  }
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: languageDetector(),
    backend: {
      /* translation file path */
      loadPath: '/assets/translations/{{lng}}.json'
    },
    fallbackLng: 'en',
    debug: true,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    keySeparator: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    }
  });

export default i18n;
