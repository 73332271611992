import React from 'react';

import {Header} from "../components/Header/Header";
import {UClub} from "../components/UClub/UClub";
import {Footer} from "../components/Footer/Footer";

export function Homepage() {
  return (
    <div id="wrap">
      <div id="main">
        <Header/>
        <UClub/>
        <Footer/>
      </div>
    </div>
  );
}

