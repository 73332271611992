import React from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';

export function AboutUsPage() {
  return (
    <div id="main">
      <Header />
      <section className="about" id="about">
        <div className="main-container">
          <div className="about__inner">
            <div className="about__header">
              <h2 class="about__title">О НАС</h2>
              <div style={{ textAlign: 'left' }}>
                <p>
                  <a href="https://makechip.club">www.makechip.club</a> —
                  профессиональный онлайн-магазин для обмена игровыми
                  предметами!
                </p>
                <p>
                  Мы стремимся предложить игрокам отличные цены и качественные
                  услуги. Мы желаем, чтобы у наших клиентов оставался только
                  положительный опыт от совершения покупок.Свяжитесь с нами,
                  если вам понадобится помощь во время совершения оплаты. Мы
                  будем признательны за обратную связь о наших услугах.
                </p>
              </div>
              <h2 class="about__title" style={{ marginTop: '60px' }}>СВЯЗАТЬСЯ С НАМИ</h2>
              <div style={{ textAlign: 'left' }}>
                <p>
                  Если у вас имеются какие-либо вопросы или предложения,
                  свяжитесь с нами по адресу:{' '}
                  <a href="mailto:info@makechip.club">info@makechip.club</a> Мы
                  ответим вам в течение 24 часов.
                </p>
                <p>Удачных покупок!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
