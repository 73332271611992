import { toast } from 'react-toastify';

const WarningToast = (message) => {
  return toast.warn(message, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined
  });
};

const ErrorToast = (message) => {
  return toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined
  });
};

const SuccessToast = (message) => {
  return toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined
  });
};

export { WarningToast, ErrorToast, SuccessToast };
