const countChips = (
  userCurrency,
  clubCurrency,
  depositValue,
  price,
  curses
) => {
  if (!userCurrency || !clubCurrency || !depositValue || !price || !curses) {
    return;
  }
  let currenctClubCurrency, userDepositCurrency;

  try {
    currenctClubCurrency = parseFloat(
      curses[clubCurrency].replace(',', '.')
    );
    userDepositCurrency = parseFloat(
      curses[userCurrency.code].replace(',', '.')
    );
  } catch (e) {
    return;
  }

  const userChips = parseFloat(
    usdToCurrency(
      currencyToUSD(depositValue, userDepositCurrency),
      currenctClubCurrency
    ) / price
  ).toFixed(2);

  return userChips;
};

const currencyToUSD = (value, curse) => {
  return curse * value;
};

const usdToCurrency = (value, curse) => {
  return value / curse;
};

export default countChips;
