import React from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';

export function DefaultTextPage({ title, body }) {
  return (
    <div id="main">
      <Header />
      <section className="about" id="about">
        <div className="main-container">
          <div className="about__inner">
            <div className="about__header">
              <h2 class="about__title">{title}</h2>
              <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: body}} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
