import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

export function Header() {
  const [menuActive, setMenuActive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [userSession, setSession] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState({
    code: i18n.language || 'ru',
  });
  const { t } = useTranslation();

  const changeLanguage = (val) => {
    setSelectedLang(val);
    i18n.changeLanguage(val.code);
  };



  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('session'));
    if (session?.email && session?.token) {
      setUserEmail(session.email)
      setSession(true)
    }

    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const logOut = () => {
    localStorage.removeItem('session');
    window.location.reload();
  }

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > 50) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  return (
    <>
      <div className="header">
        <div
          className={menuActive ? 'blur active' : 'blur'}
          onClick={() => setMenuActive(false)}
        ></div>
        <div
          className={isVisible ? 'header' : 'header header--sticky'}
          id="header"
        >
          <div className="main-container">
            <div className="header__inner">
              <div
                className={
                  menuActive ? 'header__burger active' : 'header__burger'
                }
                onClick={() => setMenuActive(!menuActive)}
              >
                <span />
              </div>

              <div
                className={menuActive ? 'menu active' : 'menu'}
                onClick={() => setMenuActive(false)}
              >
                <ul className="menu__content">
                  <li className="menu__item">
                    <Link className="menu__link" to="/">
                      {t('homePage')}
                    </Link>
                  </li>
                  <li className="menu__item">
                    <Link className="menu__link" to="/about">
                      {t('aboutUs')}
                    </Link>
                  </li>
                  <li className="menu__item">
                    <Link className="menu__link" to="/legal" state={{ tab: 0 }}>
                      {t('privacyPolicy')}
                    </Link>
                  </li>
                  <li className="menu__item">
                    <Link className="menu__link" to="/legal" state={{ tab: 1 }}>
                      {t('rules')}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="header__contollers">
                {userSession && (
                  <div className="header__user">
                    <div className="header__user-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>

                    <span className="header__user-menu">{userEmail}</span>
                    <div className="user__drop">
                      <div className="lang__drop-inner">
                        <h5>{userEmail}</h5>
                        <ul className="lang-drop-list">
                          <li className="lang-drop-list__item">
                            <div
                              className="lang-drop-list__link"
                              onClick={() => logOut()}
                            >
                              <span className="lang-drop-list__link-text">
                                {t('logOut')}
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <div className="lang">
                  <div className="lang__selected">
                    <span className="lang__selected-name">{t('lang')}</span>
                    <div className="lang__selected-icon">
                      <img src={`img/lang/${selectedLang.code}.png`} alt="" />
                    </div>
                  </div>
                  <div className="lang__drop">
                    <div className="lang__drop-inner">
                      <ul className="lang-drop-list">
                        <li className="lang-drop-list__item">
                          <div
                            className="lang-drop-list__link"
                            onClick={() => changeLanguage({ code: 'ru' })}
                          >
                            <span className="lang-drop-list__link-icon">
                              <img src="img/lang/ru.png" alt="" />
                            </span>
                            <span className="lang-drop-list__link-text">
                              Русский
                            </span>
                          </div>
                        </li>
                        <li className="lang-drop-list__item">
                          <div
                            className="lang-drop-list__link"
                            onClick={() => changeLanguage({ code: 'en' })}
                          >
                            <span className="lang-drop-list__link-icon">
                              <img src="img/lang/en.png" alt="" />
                            </span>
                            <span className="lang-drop-list__link-text">
                              English
                            </span>
                          </div>
                        </li>
                        <li className="lang-drop-list__item">
                          <div
                            className="lang-drop-list__link"
                            onClick={() => changeLanguage({ code: 'sp' })}
                          >
                            <span className="lang-drop-list__link-icon">
                              <img src="img/lang/sp.png" alt="" />
                            </span>
                            <span className="lang-drop-list__link-text">
                              Spanish
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
