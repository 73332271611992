import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="main-container">
        <img className="footer__bg" src="img/footer.svg" alt="" />
        <div className="footer__inner">
          {/* <img className="footer__logo" src="img/logo-black.svg" alt="" /> */}
          <ul className="footer-nav">
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to="/about">
                {t('aboutUs')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to="/disclaimer">
                {t('disklaimer')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to="/legal" state={{ tab: 0 }}>
                {t('privacyPolicy')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to="/legal" state={{ tab: 1 }}>
                {t('rules')}
              </Link>
            </li>
            <li className="footer-nav__item">
              <Link className="footer-nav__link" to="/legal" state={{ tab: 2 }}>
                {t('refound')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
