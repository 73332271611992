import React, { useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindowDimensions from '../hooks/useWindowDimensions';

const F11 = require('../img/welcome/w-nav-1.png');

const FILTER_DATA = [
  {
    name: 'PokerBros',
    img1: F11,
    img2: 'img/welcome/w-nav-h-1.png'
  },
  {
    name: 'PPPoker',
    img1: 'img/welcome/w-nav-2.png',
    img2: 'img/welcome/w-nav-h-2.png'
  },
  {
    name: 'ClubGG',
    img1: 'img/welcome/w-nav-3.png',
    img2: 'img/welcome/w-nav-h-3.png'
  },
  {
    name: 'Suprema Poker',
    img1: 'img/welcome/w-nav-4.png',
    img2: 'img/welcome/w-nav-h-4.png'
  }
];

const ClubsFilter = ({ toggleFilter, activeFilter }) => {
  const sliderRef = useRef(null);
  const { width } = useWindowDimensions();

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      {width < 928 ? (
        <div style={{position: 'relative'}} className="filterArrows">
          <Swiper
            breakpoints={{
              1194: { slidesPerView: 4 }
            }}
            ref={sliderRef}
            slidesPerView={1}
            loopAdditionalSlides={30}
            loop={true}
            centeredSlides={true}
            className="w-nav"
          >
            {FILTER_DATA.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="w-nav__item" style={{ paddingBottom: 20 }}>
                  <button
                    className="w-nav__btn w-nav__btn--1"
                    data-phone="w-phones-item-1"
                    onClick={() => toggleFilter(item.name)}
                  >
                    <span className="w-nav__btn-img-w">
                      <img className="w-nav__btn-img" src={item.img1} alt="" />
                    </span>
                    <span
                      className="w-nav__btn-img-h"
                      style={{ opacity: activeFilter === item.name && 1 }}
                    >
                      <img className="w-nav__btn-img" src={item.img2} alt="" />
                    </span>
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev"  onClick={handlePrev} style={{left: 10}}>
            <svg className="icon icon--angle-left" >
              <use href="img/sprite.svg#angle-left"></use>
            </svg>
          </div>
          <div className="swiper-button-next"  onClick={handleNext} style={{right: 10}}>
            <svg className="icon icon--angle-right">
              <use href="img/sprite.svg#angle-right"></use>
            </svg>
          </div>
        </div>
      ) : (
        <ul className="w-nav">
          {FILTER_DATA.map((item, index) => (
            <li className="w-nav__item" key={index}>
              <button
                className="w-nav__btn w-nav__btn--1"
                data-phone="w-phones-item-1"
                onClick={() => toggleFilter(item.name)}
              >
                <span className="w-nav__btn-img-w">
                  <img className="w-nav__btn-img" src={item.img1} alt="" />
                </span>
                <span
                  className="w-nav__btn-img-h"
                  style={{ opacity: activeFilter === item.name && 1 }}
                >
                  <img className="w-nav__btn-img" src={item.img2} alt="" />
                </span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ClubsFilter;
