import React, { useState, useEffect } from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function LegalPage() {
  let params = useLocation();
  const [tabIndex, updateIndex] = useState(params?.state?.tab || 0);
  const { t } = useTranslation();

  useEffect(() => {
    updateIndex(params?.state?.tab || 0);
    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div id="main">
      <Header />
      <section className="about" id="about">
        <div className="main-container">
          <div className="about__inner">
            <div className="about__header">
              <div className="tabs">
                <input
                  type="radio"
                  id="radio-1"
                  name="tabs"
                  onClick={() => updateIndex(0)}
                  checked={tabIndex === 0}
                />

                <label className="tab" for="radio-1">
                  {t('privacyPolicy')}
                </label>
                <div className="tab-content content-1">
                  <p>
                    This Privacy Policy applies when you use our Site (described
                    below). Please read it before using the Site because it will
                    tell you how we collect, store, use and disclose your
                    personal information when providing the Site to you. The
                    term "Company', "we", "us" and "our" in this Privacy Policy
                    refers to Skyline Digital Tech Limited. ("Skyline"){' '}
                  </p>
                  <h3>Introduction</h3>
                  <p>
                    For purposes of data protection laws, Skyline Digital Tech
                    Limited is the "data controller" of your personal
                    information. Skyline commits to comply with the provisions
                    of General Data Protection Regulation ("GDPR"), and all of
                    the other Laws and/or legal acts that are applicable, as
                    well as all of the European Union acts that are applicable
                    in accordance with the personal data protection regulations
                    that are applicable for the specific country in which the
                    services are provided. This Privacy Policy applies whenever
                    you use our "Site" and all of its "Services". "Site" means
                    makechip.io, the domain name and the subdomains linked to
                    this domain, and/or other domains or websites operated by
                    Skyline. "Services" means all content included on the Site,
                    including information, data, software, photographs, graphs,
                    videos, typefaces, graphics, and other material. It
                    describes how we collect, store, use, and disclose your
                    personal information when providing the Services to you, and
                    your rights and choices concerning your information. By
                    using the Site, you acknowledge that you have read this
                    Privacy Policy and you consent to the practices described
                    herein with respect to Skyline's collection, use, store and
                    disclosure of the information provided by you to us.{' '}
                  </p>
                  <p>
                    If you do not want Skyline to collect, store, use, or share
                    your information in the ways described in this Privacy
                    Policy, you may not use Skyline's Services. Information We
                    Collect and How We Collect It{' '}
                  </p>
                  <p>
                    Well collect and store any information that you provide to
                    us. We collect information from you when you contact us,
                    register with us, use our products and Services, submit an
                    order with us, visit our Site etc.{' '}
                  </p>
                  <h3>
                    Services, submit an order with us, visit our Site etc.{' '}
                  </h3>
                  <p>
                    Regarding the information we received from your payment,
                    please note that the purchases you make will be processed by
                    that third-party application and subject to that third-party
                    application's terms of service and privacy policy. More
                    details can be found under the subsection "How We Share
                    Information".{' '}
                  </p>
                  <h3>Cookies and Other Automated Information Collection </h3>
                  <p>
                    Some data may be collected automatically every time you
                    visit our Site, such as cookies. We use both session ID
                    cookies and persistent cookies as part of the Site's
                    interaction with your browser. A cookie is an alphanumeric
                    identifier (a file) that the Site transfers to your
                    computer's hard drive through a web browser to enable its
                    systems to recognize your browser for record-keeping
                    purposes. A session ID cookie expires when you close your
                    browser, while a persistent cookie remains on your hard
                    drive for an extended period of time. We use session ID
                    cookies to make it easier for you to navigate our Site. We
                    use persistent cookies to identify and track which sections
                    of our Site you most often visit. We also use persistent
                    cookies in areas of our Site you must register, and you are
                    able to customize the information you see, so that you don't
                    have to enter your preferences more than once. By
                    configuring the options in your browser, you may control how
                    cookies are processed by your system. However, if you
                    decline the use of cookies you may not be able to use
                    certain features on this Site and you may be required to
                    reenter the information required to complete an order during
                    new or interrupted browser sessions.
                  </p>
                  <h3>Other Sources </h3>
                  <p>
                    We may collect information from other third-party sources.
                    Where we do, we'll let you know.
                  </p>
                  <p>
                    How We Use Information We Collect and Our Legal Bases for
                    Processing It We collect your information to:- 0) provide
                    you with our products and services and administer your
                    account in relation to such products and services, in
                    particular to process your transaction, subscriptions and
                    registration for our products and services - in accordance
                    with your agreements with us.{' '}
                  </p>
                  <p>
                    (ii) process the transaction, subscriptions and registration
                    for products and services of third-parties. (iii) detect,
                    investigate and prevent fraud, to maintain the security of
                    our systems, to protect our property and safety as well as
                    property and safety of our employees. (iv) conduct research
                    and statistical analysis in order to improve and develop our
                    business, products and services. (v) provide you marketing
                    information about our products and services as well as
                    relevant third-party products and services: From time to
                    time we may also collect personal information from other
                    sources that the information subject - for example when we
                    identify beneficial owners within AML process or if you
                    provide us personal information of other persons. In many
                    cases providing information included in this policy to such
                    persons proves impossible or would involve a
                    disproportionate effort. We appreciate if you may help us to
                    be transparent to such persons on processing of their
                    information. Such information are not used for marketing
                    purposes.
                  </p>
                  <p>
                    We commit to comply with the provisions of GDPR, and all of
                    the other Laws and/or legal acts that are applicable, as
                    well as all of the European Union acts that are applicable
                    in accordance with the Personal data protection regulations
                    that are applicable for the specific country in which the
                    services are provided. As required by law, and to enforce
                    customers, or our legal rights, and to comply with local,
                    state, federal and international law, we may disclose
                    information to law enforcement agencies.
                  </p>
                  <p>
                    How We Share Information Well share your information with
                    third parties when you use our Services under the
                    circumstances described in this subsection. Third-Party
                    Service Providers Well share your information with the
                    third-party service providers that we work with to bring you
                    our Services. We will share your information with
                    third-party companies who perform services on our behalf,
                    like payment processing. We will also share your information
                    with our third-party service providers who assist us in
                    delivering and optimizing our Services, such as gaming
                    cornpanies. Where we share your information with third-party
                    service providers, we ensure that we have appropriate
                    agreements in place to protect your information (as required
                    by applicable data protection laws).
                  </p>
                  <p>
                    Safety, Security, and Compliance with Law Well share your
                    information if necessary, for safety, security, or
                    compliance with law. Your information, and the contents of
                    all of your online communications in our Services and
                    between you and Skyline may be accessed and monitored as
                    needed to provide our Service and may be disclosed: • to
                    satisfy any laws or regulations that apply; • when we have a
                    good faith belief that we have to disclose the information
                    in response to lawful requests by public authorities,
                    including for national security, law enforcement, or legal
                    process purposes (for example, a court order, search
                    warrant, or subpoena); • when we believe in our sole
                    discretion that our Services are being used in committing a
                    crime, including to report such criminal activity or to
                    share information with other companies and organizations for
                    the purposes of fraud protection, credit risk reduction, and
                    other security precautions; • when we have a good faith
                    belief that there is an emergency that poses a threat to the
                    health and/or safety of you, another person, or the public
                    generally; and/or • to protect the rights or property of
                    Skyline or applicable third parties, including to enforce
                    our Terms of Use.
                  </p>
                  <p>
                    Sale or Merger Well share your information with the
                    interested party if we undergo or evaluate undergoing a sale
                    or merger. In the event that Skyline undergoes or evaluates
                    undergoing a business transition, like a merger, acquisition
                    by another company, or sale of all or part of its assets
                    (like selling a game), we may disclose or transfer all of
                    your information, including personal information, to the
                    successor organization in the transition. We will make
                    reasonable efforts to let you and other players know (in the
                    way described in Changes to our Privacy Policy) of the
                    business transition before transferring your personal
                    information.{' '}
                  </p>
                  <p>
                    Your Rights and How to Access, Update, and Manage Your
                    Personal Information Pursuant to the GDPR, you have
                    following rights in relation to the processing of your
                    personal information: • Right to be informed, which is
                    satisfied through this notice. • Right to erasure. You have
                    the right to have your data erased and no longer processed
                    if the personal data are no longer necessary in relation to
                    the purposes for which they were collected or otherwise
                    processed, if consent was withdrawn or objection was filed
                    and canerre no other legal basis for processing. If we have
                    disclosed the personal data in question to third parties, we
                    will inform them about the erasure of the personal data,
                    unless it is impossible or involves disproportionate effort
                    to do so. • Right to restriction on processing. If there are
                    grounds for restriction on processing, for example if you
                    contest the accuracy of your personal data, it will be
                    stored and processed otherwise only if you consent or to
                    exercise legal claims, or for the protection of rights of
                    another natural or legal person. If we have disclosed the
                    personal data in question to third parties, we will inform
                    them about the restriction on the processing of the personal
                    data, unless it is impossible or involves disproportionate
                    effort to do so. We will inform you before the restriction
                    is lifted. • Right to objection to processing. You have the
                    right to object to processing based on legitimate interests
                    (including profiling) and direct marketing (including
                    profiling). We will no longer process your data, unless we
                    have compelling legitimate grounds for processing or we need
                    to process the data for the establishment, exercise or
                    defense of legal claims.{' '}
                  </p>
                </div>

                <input
                  type="radio"
                  id="radio-2"
                  name="tabs"
                  onClick={() => updateIndex(1)}
                  checked={tabIndex === 1}
                />

                <label className="tab" for="radio-2">
                  {t('rules')}
                </label>
                <div className="tab-content content-2">
                  <p>
                    This site is owned and/or operated by Skyline Digital Tech
                    Limited (hereinafter referred to as the "Company", "we",
                    "our" as appropriate in the context). If you visit or shop
                    at www.makechip.io, you affirmatively accept the following
                    conditions. Continued use of the site constitutes the
                    affirmative agreement to these terms and conditions. The
                    Company reserves the right to change the terms, conditions,
                    and notices under which its websites and services am
                    offered, including but not limited to the charges associated
                    with the use of the Company's websites and services. You are
                    responsible for regularly reviewing these terms and
                    conditions and any additional terms and conditions. Your
                    continued use of the Company's websites and services
                    constitutes your agreement to all such terms, conditions,
                    and notices (see TERMS & CONDITIONS and the PRIVACY POLICY).
                    Please read them carefully.
                  </p>
                  <h3>PRIVACY</h3>
                  <p>
                    Please review our PRIVACY POLICY, which also governs your
                    visit to the Company's websites.
                  </p>
                  <h3>ELECTRONIC COMMUNICATIONS</h3>
                  <p>
                    When you visit the Company's websites or send e-mails to us,
                    you are communicating with us electronically. You consent to
                    receive communications from us electronically. We will
                    communicate with you by e-mail or by posting notices on this
                    site. You agree that all agreements, notices, disclosures
                    and other communications that we provide to you
                    electronically satisfy any legal requirement that such
                    communications be in writing.{' '}
                  </p>
                  <h3>COPYRIGHT</h3>
                  <p>
                    All content on this site, such as text, graphics, logos,
                    button icons, images, audio clips, digital downloads, data
                    compilations, and software, is the property of the Company
                    or its content suppliers and is protected by domestic and
                    international copyright laws. The compilation of all content
                    on this site is the exclusive property of the Company and is
                    protected by domestic and international copyright laws. All
                    software used on this site is the property of the Company or
                    its software suppliers and is protected by domestic and
                    international copyright laws.
                  </p>
                  <h3>LICENSE AND SITE ACCESS </h3>
                  <p>
                    The Company grants you a limited license to access and make
                    personal use of this site and not to download (other than
                    page caching) or modify it, or any portion of it, except
                    with express written consent of the Company. This license
                    does not include any resale or commercial use of this site
                    or its contents; any collection and use of any product
                    listings, descriptions, or prices; any derivative use of
                    this site or its contents; any downloading or copying of
                    account information for the benefit of another merchant; or
                    any use of data mining, robots, or similar data gathering
                    and extraction services. This site or any portion of this
                    site may not be reproduced, duplicated, copied, sold,
                    resold, visited, or otherwise exploited for any commercial
                    purpose without express written consent of the Company. The
                    use of spiders, robots, scrapers or any other means, whether
                    through the use of automated software or through a physical
                    or mechanical system, is strictly prohibited. You may not
                    frame or utilize framing techniques to enclose any trade
                    mark, logo, or other proprietary information (including
                    images, text, page layout, or form) of the Company and our
                    affiliates without express written consent of the Company.
                    You may not use any meta tags or any other 'hidden text'
                    utilizing the Company's name or trade marks without the
                    express written consent of the Company. Any unauthorized use
                    terminates the permission or license granted by the Company.
                    You may not use any Company logos or other proprietary
                    graphic or trade mark as part of the link without express
                    written permission of the Company.{' '}
                  </p>
                  <h3>YOUR ACCOUNT </h3>
                  <p>
                    If you use this site, you are responsible for entering the
                    correct information in relation to third party gaming
                    platforms. If you are under the age of 18, you may use the
                    Company's services only with involvement of a parent or
                    guardian. The Company and its affiliates reserve the right
                    to refuse service, remove or edit content, or cancel orders
                    in their sole discretion.{' '}
                  </p>
                  <h3>DESCRIPTION OF SITE AND SERVICES </h3>
                  <p>
                    Through this website, the Company provides you with access
                    to a variety of resources, including the purchase of virtual
                    items and accounts. All references to "game items" and
                    "virtual items" on this site refer to PPPoker diamonds in
                    the PPPoker app. Such services, including any updates,
                    enhancements, new features, and/or the addition of any new
                    Web properties, are subject to these Terms and Conditions.
                    The Company may also provide links and pointers to Internet
                    sites maintained by third-parties. Neither the Company, its
                    parent or subsidiary companies, nor their affiliates operate
                    or control in any respect of any information, products or
                    services on these third-party sites. We are not responsible
                    for examining or evaluating, and we do not warrant the
                    offerings of, any of these businesses or individuals or the
                    content of their websites. The Company does not assume any
                    responsibility or liability for the actions, product, and
                    content of all these and any other third parties. You should
                    carefully review their privacy statements and other
                    conditions of use.{' '}
                  </p>
                  <p>
                    The material in this site and the third-party sites are
                    provided 'as is' and without warranties of any kind either
                    expressed or implied. To the fullest extent permissible
                    pursuant to the applicable law, the Company disclaims all
                    warranties, expressed or implied, including, but not limited
                    to, implied warranties of merchantability and fitness for
                    particular purpose. The Company makes no representation or
                    claims to any title for any virtual items purchased through
                    this site. The Company further makes no representations
                    regarding the transferability, use, and ownership of any
                    such accounts or virtual items. The Company is not
                    affiliated with any publisher or developer of any game.{' '}
                  </p>
                  <h3>PRICE MATCHING</h3>
                  <p>
                    This site does not currently support Price Matching. If you
                    find a better price at another online store we will do our
                    best to beat it.
                  </p>
                  <h3>REFUND AND CANCELLATION POLICY </h3>
                  <p>
                    We allow you to request refunds for products under
                    reasonable conditions. If you initiate a refund request, we
                    may issue refunds under the following conditions: 1.
                    Products not received: The products were not delivered to
                    you within the scheduled delivery time. 2. Repeat payment:
                    You made repeated payments to one order. In this case, we
                    can respect your willingness to request a refund. We will
                    refund one of the payments, or multiple payments. If we
                    decide to refund you, it may be for the following reason: 1.
                    Sometimes we need your assistance to verify payment
                    information. If you refuse it or do not respond to our
                    request within seven (7) days, your order will be cancelled
                    and refunded immediately. In the following cases, we cannot
                    refund to you: 1. Completed delivery: the products have been
                    delivered to you as agreed and consistent with the
                    description. 2. Unpaid Order: You are not allowed to apply
                    for a refund for the unpaid order. About the refund under
                    reasonable conditions, please contact our customer service
                    via info@Makechip.io. Your refund request will be verified
                    after you submit the order number and the reason for the
                    refund. If the request is approved, the refund will be
                    returned to the relevant account. Refund processing time: We
                    will process the refund request in five (5) working days,
                    but the exact time of refund is determined by the processing
                    speed of the different banks.{' '}
                  </p>
                  <h3>PRODUCT DESCRIPTIONS </h3>
                  <p>
                    The Company and its affiliates attempt to be as accurate as
                    possible in describing a product or service. However, the
                    Company does not warrant that product descriptions or other
                    content of this site is accurate, complete, reliable,
                    current, or error-free. If a product offered by the Company
                    itself is not as described, your sole remedy is to return it
                    within twenty (24) hours of purchase.
                  </p>
                  <h3>SHIPPING & HANDLING</h3>
                  <p>Shipping & Handling is included unless specified. </p>
                  <h3>BILLING</h3>
                  <p>
                    Please note that we charge your credit card before your
                    order enters the shipping/delivery process.{' '}
                  </p>
                  <h3>RESTRICTED USERS</h3>
                  <p>
                    No person, employee, agent or entity affiliated in any way
                    to any game publisher or developer is authorized to use or
                    access this site or to utilize the services provided herein.
                  </p>
                  <h3>COPYRIGHT COMPLAINTS</h3>
                  <p>
                    The Company and its affiliates respect the intellectual
                    property of others. If you believe that your work has been
                    copied in a way that constitutes copyright infringement,
                    please contact our legal department
                    (Legal@skylinedigital.net) about making claims of
                    intellectual property rights infringement.
                  </p>
                  <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY </h3>
                  <p>
                    THIS SITE IS PROVIDED BY THE COMPANY ON AN 'AS IS' AND 'AS
                    AVAILABLE' BASIS, THE COMPANY MAKES NO REPRESENTATIONS OR
                    WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                    OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
                    MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU EXPRESSLY
                    AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK, TO
                    THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, THE COMPANY
                    DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT
                    NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND
                    FITNESS FORA PARTICULAR PURPOSE. THE COMPANY DOES NOT
                    WARRANT THAT THIS SITE, ITS SERVERS, ORE-MAIL SENT THE
                    COMPANY ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE
                    COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                    ARISING THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO
                    DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
                    DAMAGES. CERTAIN LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                    WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES.{' '}
                  </p>
                  <h3>INDEMNIFICATION</h3>
                  <p>
                    You agree to indemnify and hold the Company and any of its
                    parent, subsidiaries, affiliates, officers, directors,
                    agents, and employees, harmless of any claim or demand,
                    including reasonable attorneys' fees, made by any third
                    party due to or arising out of your breach of these Terms
                    and Conditions or any terms and conditions it incorporates
                    by reference, or your violation of any law or the rights of
                    a third party.
                  </p>
                  <h3>DISPUTES</h3>
                  <p>
                    Any dispute relating in any way to these Terms and
                    Conditions, your visit to the Company's websites or the
                    products and/or services you purchase through the Company
                    shall be exclusively submitted to arbitration in Hong Kong,
                    except that, to the extent you have in any manner violated
                    or threatened to violate the Company's intellectual property
                    rights, the Company may seek injunctive or other appropriate
                    relief in any court in the world, and you consent to
                    exclusive jurisdiction and venue in such courts. Arbitration
                    under these Terms and Conditions shall be conducted under
                    the rules then prevailing in Hong Kong. The arbitrator's
                    award shall be binding and may be entered as a judgment in
                    any court of competent jurisdiction. To the fullest extent
                    permitted by applicable law, no arbitration under these
                    Terms and Conditions shall be joined to an arbitration
                    involving any other party subject to these Terms and
                    Conditions, whether through class arbitration proceedings or
                    otherwise.{' '}
                  </p>
                  <h3>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h3>
                  <p>
                    Please review our other policies, such as our PRIVACY
                    POLICY, posted on this site. These policies also govern your
                    visit to the Company's websites. We reserve the right to
                    make changes to our site, policies, and these TERMS &
                    CONDITIONS at any time. If any of these conditions shall be
                    deemed invalid, void, or for any reason unenforceable, that
                    condition shall be deemed severable and shall not affect the
                    validity and enforceability of any remaining condition.{' '}
                  </p>
                  <h3>WAIVER</h3>
                  <p>
                    The failure of the Company to enforce any provision of these
                    Terms and Conditions shall not be construed as a waiver or
                    limitation of the Company's right subsequently to enforce
                    and compel strict compliance with every provision of these
                    Terms and Conditions.
                  </p>
                  <h3>REMEDIES</h3>
                  <p>
                    You acknowledge that violation of these Terms and Conditions
                    could cause irreparable harm for which monetary damages may
                    be difficult to ascertain or an inadequate remedy. You
                    therefore, agree that the Company shall be entitled, in
                    addition to its other rights, to seek and obtain injunctive
                    relief for any violation of these Terms and Conditions
                    without the filing or posting of any bond or surety.
                    Furthermore, in the event a lawsuit or action is brought by
                    any party under these Terms and Conditions to enforce any of
                    its terms, it is agreed that if the Company shall be deemed
                    to be the prevailing party as determined by final judgment,
                    not subject to appeal, of a court of competent jurisdiction,
                    the Company shall be entitled to recovery of reasonable
                    attorney's fees, expert fees, litigation expenses and court
                    costs in addition to any other relief granted by the court.{' '}
                  </p>
                  <h3>SEVERABILITY</h3>
                  <p>
                    If any term or provision in these Terms and Conditions is
                    found to be void, against public policy, or unenforceable by
                    a court of competent jurisdiction and such finding or order
                    becomes final and non-appealable, then, the offending
                    provision shall be deemed modified to the extent necessary
                    to make it valid and enforceable. If the offending provision
                    cannot be so modified, then the same shall be deemed
                    stricken here in its entirety, and unless such term or
                    provision is material to the performance of these Terms and
                    Conditions, the remainder of these Terms and Conditions
                    shall survive with the said offending provision eliminated.{' '}
                  </p>
                </div>

                <input
                  type="radio"
                  id="radio-3"
                  name="tabs"
                  onClick={() => updateIndex(2)}
                  checked={tabIndex === 2}
                />

                <label className="tab" for="radio-3">
                  {t('refound')}
                </label>
                <div className="tab-content content-3">
                  <p>
                    We allow you to request refunds for products under
                    reasonable conditions.
                  </p>
                  <p>
                    If you initiate a refund request, we may issue refunds under
                    the following conditions:{' '}
                  </p>
                  <p>
                    1. Products not received: The products were not delivered to
                    you within the scheduled delivery time.{' '}
                  </p>
                  <p>
                    2. Repeat payment: You made repeated payments to one order.
                    In this case, we can respect your willingness to request a
                    refund. We will refund one of the payments, or multiple
                    payments.{' '}
                  </p>
                  <p>
                    If we decide to refund you, it may be for the following
                    reason:{' '}
                  </p>
                  <p>
                    Sometimes we need your assistance to verify payment
                    information. If you refuse it or do not respond to our
                    request within seven (7) days, your order will be cancelled
                    and refunded immediately.{' '}
                  </p>
                  <p>In the following cases, we cannot refund to you: </p>
                  <p>
                    1. Completed delivery: the products have been delivered to
                    you as agreed and consistent with the description.{' '}
                  </p>
                  <p>
                    2. Unpaid Order: You are not allowed to apply for a refund
                    for the unpaid order.{' '}
                  </p>
                  <p>
                    About the refund under reasonable conditions, please contact
                    our customer service via info@Makechip.io. Your refund
                    request will be verified after you submit the order number
                    and the reason for the refund. If the request is approved,
                    the refund will be returned to the relevant account.{' '}
                  </p>
                  <p>
                    Refund processing time: We will process the refund request
                    in five (5) working days, but the exact time of refund is
                    determined by the processing speed of the different banks.
                  </p>
                </div>
                <span className="glider"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
