import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import UClubSlide from './UClubSlide';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useForm } from 'react-hook-form';
import { Rings, Grid } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import CurrencyInput from 'react-currency-input-field';
import InputMask from 'react-input-mask';
import CLUBS_QUERY from '../../queries/clubsQuey';
import ClubsFilter from '../clubsFilter';
import { WarningToast, ErrorToast, SuccessToast } from '../../plugins/Toasts';
import countChips from '../../utils/countChips';

import {
  confirmEmail,
  userVerification,
  initPayment,
  getCourenccyes
} from '../../api/index';

export function UClub() {
  const lang = navigator.language || navigator.userLanguage;
  const [clubs, setClubs] = useState([]);
  const [codeLoading, toggleCodeLoading] = useState(false);
  const [successCodeLoading, toggleSuccessCodeLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [clubFilter, toggleClubFilter] = useState();
  const [clubsList, setClubsList] = useState(undefined);
  const [userEmail, setUserEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [authToken, toggleAuthToken] = useState(undefined);
  const [userSession, setSession] = useState(false);
  const [checked, setChecked] = useState(false);
  const [captcha, confirmCaptcha] = useState(false);
  const [depositValue, setDepositValue] = useState(0);
  const [clubId, setClubId] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [curses, setCurses] = useState(undefined);
  const [platform, setPlatform] = useState(undefined);
  const [chips, setChips] = useState(undefined);
  const { loadingQuery, error } = useQuery(CLUBS_QUERY, {
    onCompleted: (data) => setClubs(data)
  });
  const [currency, setCurrency] = useState(
    lang == 'ru'
      ? {
          code: 'RUB',
          symbol: '₽',
          name: 'Рубль'
        }
      : {
          code: 'USD',
          symbol: '$',
          name: 'Dollars'
        }
  );
  const { t, i18n } = useTranslation();

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit
  } = useForm();

  const submitMailForm = (data) => {
    if (!captcha) {
      WarningToast(t('error_captcha'));
      return;
    }

    toggleCodeLoading(true);

    confirmEmail(data.userId, data.mail)
      .then((response) => {
        toggleCodeLoading(false);
        SuccessToast(t('code_sended'));
        toggleSuccessCodeLoading(true);
        setTimeout(() => {
          toggleSuccessCodeLoading(false);
        }, 5000);
      })
      .catch((error) => {
        ErrorToast(t('error'));
      })
      .finally(() => {
        setUserEmail(data.mail);
        setUserId(data.userId);
        toggleCodeLoading(false);
      });
  };

  const handleFilter = (value) => {
    toggleClubFilter(value);

    setClubId('');
  };

  const getFilteredList = () => {
    if (!clubFilter) {
      return clubsList;
    }
    return clubsList.filter(
      (item) => item.categories.nodes[0]?.name === clubFilter
    );
  };

  var filteredList = useMemo(getFilteredList, [clubFilter, clubsList]);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setClubsList(
      clubs?.clubs?.nodes.filter(
        (item) => item.clubFields.showOnMakechip !== 'no'
      )
    );
    setClubId(clubs?.clubs?.nodes[0].clubFields.clubId);
  }, [clubs]);

  useEffect(() => {
    if (clubs?.clubs?.nodes?.length > 0) {
      const activeClub = clubs?.clubs?.nodes.filter(
        (item) => item.clubFields.clubId === clubId
      );
      const clubValues = activeClub[0]?.clubFields?.info?.course
        .split('-')
        .pop()
        .trim()
        .split(' ');
      if (clubValues && clubValues.length > 0) {
        const clubPrice = clubValues[0] || null;
        const clubCurrency = clubValues[1] || null;
        setChips(
          countChips(currency, clubCurrency, depositValue, clubPrice, curses)
        );
      }
    }
  }, [clubId, currency, depositValue, clubs, curses]);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem('session'));
    if (session?.email && session?.token) {
      toggleAuthToken(session.token)
      setUserEmail(session.email)
      setUserId(session.userId)
      setValue('userId', session.userId);
      setSession(true)
      setChecked(true)
    }

    getCourenccyes()
      .then((resp) => setCurses(resp.data))
      .catch((err) => console.log(err));
  }, []);

  const depositChange = (e) => {
    e.preventDefault();
    const { value = '' } = e.target;
    const parsedValue = value.replace(/[^\d.]/gi, '');
    setDepositValue(parsedValue);
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (sliderRef?.current?.swiper !== null) {
        setClubId(
          parseInt(
            sliderRef?.current?.swiper?.$el[0]
              ?.getElementsByClassName('swiper-slide-active')[0]
              ?.getElementsByClassName('clubID')[0]?.innerText
          )
        );
      }
    }, 300);
  }, [sliderRef?.current?.swiper]);

  useEffect(() => {
    try {
      const activeClub = clubs?.clubs?.nodes.filter(
        (item) => item.clubFields.clubId === clubId
      );
      const platform = activeClub[0]?.categories.nodes[0]?.name.trim();
      setPlatform(platform);
    } catch {}
  }, [clubId, clubs]);

  useEffect(() => {
    if (i18n.language == 'ru') {
      setCurrency({
        code: 'RUB',
        symbol: '₽',
        name: 'Рубль'
      });
    } else {
      setCurrency({
        code: 'USD',
        symbol: '$',
        name: 'Dollars'
      });
    }
  }, [i18n.language]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  function onChange(value) {
    confirmCaptcha(value);
  }

  const payFormConfirm = (e) => {
    e.preventDefault();
    let settedUserId = getValues('userId')

    if (settedUserId.includes('-')) {
      settedUserId = settedUserId.replace('-', '')
    }

    if (!/^\d+$/.test(settedUserId)) {
      WarningToast(t('error_club_id_formatt'));
      const element = document.getElementById('clubs');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    if (!getValues('userId') || !verificationCode.length === 6) {
      WarningToast(t('error_verification'));
      const element = document.getElementById('u-club');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    if (!clubId) {
      WarningToast(t('error_club'));
      const element = document.getElementById('clubs');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }

    if (!checked) {
      WarningToast(t('error_rules'));
      return;
    }

    if (depositValue === 0) {
      WarningToast(t('error_amount'));
      return;
    }

    if (!userSession) {
      userVerification(userEmail, verificationCode.replace(/[^\w\s]/gi, ''))
        .then((response) => {
          toggleAuthToken(response.data.token);
          localStorage.setItem(
            'session',
            JSON.stringify({
              email: userEmail,
              token: response.data.token,
              userId: settedUserId
            })
          );

          if (response?.data?.token) {
            initPayment(
              depositValue,
              currency.code,
              clubId,
              settedUserId,
              response.data.token,
              platform,
              chips
            )
              .then((response) => {
                window.open(response.data.url, '_blank');
                window.location.reload();
              })
              .catch((error) => {
                ErrorToast(t('error'));
                console.log('pay', error);
              });
          }
        })
        .catch((error) => {
          ErrorToast(t('error'));
          console.log('verification', error);
        });
    } else {
      let windowReference = window.open();

      initPayment(
        depositValue,
        currency.code,
        clubId,
        settedUserId,
        authToken,
        platform,
        chips
      )
        .then((response) => {
          windowReference.location = response.data.url;
          window.location.reload();
        })
        .catch((error) => {
          windowReference.close();
          ErrorToast(t('error'));
          console.log('pay', error);
        });
    }
   
  };

  if (!clubs || !curses)
    return (
      <div className="preloaderWrapper">
        <Grid color="#E1B048" height={80} width={80} />
      </div>
    );
  if (error) return <pre>{error.message}</pre>;
  return (
    <>
      <section className="u-club" id="u-club" style={{ paddingTop: '120px' }}>
        <div className="welcome">
          <div className="welcome__poligon-bg welcome__poligon-bg--1"></div>
          <div className="welcome__poligon-bg welcome__poligon-bg--2"></div>
        </div>

        <div className="main-container">
          <form
            className="form-wrapper"
            style={{ marginBottom: '30px' }}
            onSubmit={handleSubmit(submitMailForm)}
          >
            <div className="form-row">
              <fieldset>
                {errors.userId ? (
                  <span className="id error-msg">{errors.userId?.message}</span>
                ) : (
                  <label className="id">{t('inputYourId')}</label>
                )}
                <input
                  className={`userId ${errors.userId && 'error-input'}`}
                  id="id"
                  name="id"
                  type="text"
                  {...register('userId', {
                    required: t('error_required'),
                    maxLength: 20
                  })}
                />
                <div className="form-reqs">{t('idInformation')}</div>
              </fieldset>

              <ReCAPTCHA
                className="captcha"
                sitekey="6LeCN9AgAAAAAHzrXXam7jPvNYZ4HHsdfwiEnHVK"
                onChange={onChange}
                hl={lang}
              />
            </div>

            {!userSession && (
              <div className="form-row">
                <fieldset className="email-wrap">
                  {errors.mail ? (
                    <span className="id error-msg">{errors.mail?.message}</span>
                  ) : (
                    <label className="id enterEmail">{t('enterEmail')}</label>
                  )}
                  <div className="emailWrapper">
                    <input
                      className={`userEmail ${errors.mail && 'error-input'}`}
                      id="email"
                      name="mail"
                      type="mail"
                      {...register('mail', {
                        required: t('error_required'),
                        maxLength: 40
                      })}
                    />
                    <div className="codeBtnWrapper">
                      <button
                        className={`codeBtn ${
                          successCodeLoading && 'success-btn'
                        }`}
                        type="submit"
                      >
                        {codeLoading ? (
                          <Rings color="white" height={40} width={40} />
                        ) : (
                          <span className="btnText">
                            {successCodeLoading ? t('sent') : t('getCode')}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="code-wrap">
                  <label className="code">{t('enterCode')}</label>
                  <InputMask
                    className="userCode"
                    mask="99-99-99"
                    maskChar="_"
                    id="code"
                    name="code"
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </fieldset>
              </div>
            )}
          </form>

          <div style={{ maxWidth: '930px', margin: 'auto' }}>
            <span className="form-label">{t('chooseClub')}</span>
          </div>
          <ClubsFilter
            toggleFilter={(value) => handleFilter(value)}
            activeFilter={clubFilter}
          />
        </div>

        {!clubsList || clubsList?.length === 0 ? (
          <div
            style={{
              padding: '60px 0',
              width: '100%',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Rings color="#E1B048" height={90} width={90} />
          </div>
        ) : (
          <div className="u-club-slider-wrap" id="clubs">
            <div className="u-club-slider">
              {filteredList ? (
                <>
                  {filteredList?.length < 4 && width > 1194 ? (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      {filteredList.map((item, index) => (
                        <UClubSlide
                          key={index}
                          selected={clubId}
                          setSelected={() => setClubId(item.clubFields.clubId)}
                          index={index}
                          title={item.title}
                          id={item.clubFields.clubId}
                          country={item.clubFields.info.country}
                          union={item.clubFields.info.union}
                          rakeback={item.clubFields.info.rakenack}
                          price={item.clubFields.info.course}
                          img={item.clubFields.clubImage.sourceUrl}
                          link={item.clubFields.link}
                        />
                      ))}
                    </div>
                  ) : (
                    <Swiper
                      breakpoints={{
                        1194: { slidesPerView: 4 }
                      }}
                      ref={sliderRef}
                      slidesPerView={1}
                      loopAdditionalSlides={30}
                      loop={true}
                      centeredSlides={true}
                      className="mySwiper u-club-swiper"
                      onInit={(swiper) =>
                        setClubId(
                          parseInt(
                            swiper?.el
                              ?.getElementsByClassName('swiper-slide-active')[0]
                              ?.getElementsByClassName('clubID')[0]?.innerText
                          )
                        )
                      }
                      onSlideChangeTransitionEnd={(swiper) =>
                        setClubId(
                          parseInt(
                            swiper?.el
                              .getElementsByClassName('swiper-slide-active')[0]
                              .getElementsByClassName('clubID')[0].innerText
                          )
                        )
                      }
                    >
                      {filteredList.map((item, index) => (
                        <SwiperSlide key={index}>
                          <UClubSlide
                            index={index}
                            selected={clubId}
                            setSelected={() =>
                              setClubId(item.clubFields.clubId)
                            }
                            title={item.title}
                            id={item.clubFields.clubId}
                            country={item.clubFields.info.country}
                            union={item.clubFields.info.union}
                            rakeback={item.clubFields.info.rakenack}
                            price={item.clubFields.info.course}
                            img={item.clubFields.clubImage.sourceUrl}
                            link={item.clubFields.link}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </>
              ) : null}
            </div>

            {((filteredList?.length > 3 && width > 1194) ||
              (filteredList?.length > 1 && width < 1194)) && (
              <>
                <div className="swiper-button-prev" onClick={handlePrev}>
                  <svg className="icon icon--angle-left">
                    <use href="img/sprite.svg#angle-left"></use>
                  </svg>
                </div>

                <div className="swiper-button-next">
                  <svg className="icon icon--angle-right" onClick={handleNext}>
                    <use href="img/sprite.svg#angle-right"></use>
                  </svg>
                </div>
              </>
            )}
          </div>
        )}

        <form className="second-form-wrapper">
          <div className="content-wrap">
            <fieldset className="deposit-wrap">
              <label className="deposit">{t('buyON')}</label>

              <div className="deposit-filter-wrap">
                <CurrencyInput
                  className="userDeposit"
                  name="deposit"
                  id="deposit"
                  data-number-to-fixed="2"
                  data-number-stepfactor="100"
                  value={depositValue}
                  placeholder=""
                  required="required"
                  onChange={depositChange}
                  allowDecimals
                  decimalsLimit="2"
                  maxLength="5"
                  disableAbbreviations
                />
                <div className="currency">
                  <div className="currency__selected">
                    <span className="currency__selected-name">
                      {currency.symbol}
                    </span>
                  </div>
                  <div className="currency__drop">
                    <div className="currency__drop-inner">
                      <ul className="currency-drop-list">
                        <li className="currency-drop-list__item">
                          <div
                            className="currency-drop-list__link"
                            onClick={() =>
                              setCurrency({
                                code: 'RUB',
                                symbol: '₽',
                                name: 'Рубль'
                              })
                            }
                          >
                            <span className="currency-drop-list__link-currency">
                              ₽
                            </span>
                            <span className="currency-drop-list__link-text">
                              Рубль
                            </span>
                          </div>
                        </li>
                        <li className="currency-drop-list__item">
                          <div
                            className="currency-drop-list__link"
                            onClick={() =>
                              setCurrency({
                                code: 'USD',
                                symbol: '$',
                                name: 'Dollars'
                              })
                            }
                          >
                            <span className="currency-drop-list__link-currency">
                              $
                            </span>
                            <span className="currency-drop-list__link-text">
                              Dollars
                            </span>
                          </div>
                        </li>
                        <li className="currency-drop-list__item">
                          <div
                            className="currency-drop-list__link"
                            onClick={() =>
                              setCurrency({
                                code: 'EUR',
                                symbol: '€',
                                name: 'Euro'
                              })
                            }
                          >
                            <span className="currency-drop-list__link-currency">
                              €
                            </span>
                            <span className="currency-drop-list__link-text">
                              Euro
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="privacyTerm-wrap">
                <input
                  className="privacyCheckbox"
                  id="checkbox"
                  type="checkbox"
                  name="checkbox"
                  checked={checked}
                  onChange={handleChange}
                />
                <label className="checkboxText">
                  {t('readPlease')}{' '}
                  <Link to="/legal" state={{ tab: 0 }}>
                    {t('privacyPolicy')}
                  </Link>{' '}
                  {t('and')}{' '}
                  <Link to="/legal" state={{ tab: 1 }}>
                    {t('rules')}
                  </Link>
                </label>
              </div>
            </fieldset>
            <div className="form-btn-group">
              <button
                className="continueBtn"
                type="submit"
                //disabled={!paymentConfitmation}
                onClick={(e) => payFormConfirm(e)}
              >
                {t('continue')}
              </button>
              {chips > 0 && (
                <span className="chipsMsg">
                  {t('youGet')}{' '}
                  {chips < 9999999999999 ? '≈' + chips : t('aLot')} {t('chips')}
                  *
                </span>
              )}
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
