import React, { useEffect } from 'react';
import { Header } from '../components/Header/Header';
import { Footer } from '../components/Footer/Footer';
import { useTranslation } from 'react-i18next';
import { hello } from '../api/index';
import queryString from 'query-string';

export function NotificationsPage(props) {
  const { t } = useTranslation();
  //const [params, setParams] = useState([])

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);

    if (queryParams) {
      hello(queryParams)
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    console.log(queryParams);
  }, []);

  return (
    <div id="wrap" className="final">
      <div id="main">
        <Header />
        <div className="main-container">
          <div className="final-wrapper">
            <h1>{t('notifications')}</h1>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
