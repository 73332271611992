import React from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'swiper/css';
import './scss/main.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-toastify/dist/ReactToastify.css';

import { Homepage } from './pages/Homepage';
import { LegalPage } from './pages/LegalPage';
import { DefaultTextPage } from './pages/DefaultTextPage';
import { AboutUsPage } from './pages/AboutUsPage';
import { SuccessPage } from './pages/SuccessPage';
import { ErrorPage } from './pages/ErrorPage';
import { NotificationsPage } from './pages/NotificationsPage';

import disclaimer from './data/disclaimer.json';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/hello" element={<NotificationsPage />} />
          <Route
            path="/disclaimer"
            element={
              <DefaultTextPage
                title={disclaimer.title}
                body={disclaimer.body}
              />
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer theme="colored" />
    </>
  );
}

export default App;
