import React from 'react';
import { useTranslation } from 'react-i18next';

export default function UClubSlide({
  title,
  id,
  country,
  union,
  rakeback,
  img,
  selected,
  setSelected,
  price,
  index,
  link
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="swiper-slide">
        <div
          onClick={setSelected}
          className="u-club-slider__inner "
          style={{
            boxShadow: selected === id && '0px 8px 34px #e1b048',
            backgroundColor: selected === id && 'rgb(205 252 207)'
          }}
        >
          <h3
            className="u-club-slider__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="u-club-slider__id">
            ID <span className="clubID">{id}</span>
          </div>
          <div className="u-club-slider__img-w">
            <img className="u-club-slider__img" src={img} alt="" />
          </div>
          <div className="u-club-slider__cont">
            <div className="u-club-slider__cont-inner">
              <div className="u-club-slider__cont-img-w">
                <img
                  className="u-club-slider__cont-img"
                  src="img/russia.svg"
                  alt=""
                />
              </div>
              <ul className="u-club-s-list">
                <li className="u-club-s-list__item">
                  <div className="u-club-s-list__icon">
                    <svg className="icon icon--flag">
                      <use href="img/sprite.svg#flag"></use>
                    </svg>
                  </div>
                  <div className="u-club-s-list__label">{`${t(
                    'country'
                  )}:`}</div>
                  <div className="u-club-s-list__text-info">{`${t(country)}:`}</div>
                </li>
                <li className="u-club-s-list__item">
                  <div className="u-club-s-list__icon">
                    <svg className="icon icon--boxes">
                      <use href="img/sprite.svg#boxes"></use>
                    </svg>
                  </div>
                  <div className="u-club-s-list__label">{`${t('union')}:`}</div>
                  <div className="u-club-s-list__text-info">{union}</div>
                </li>
                <li className="u-club-s-list__item">
                  <div className="u-club-s-list__icon">
                    <svg className="icon icon--coins">
                      <use href="img/sprite.svg#coins"></use>
                    </svg>
                  </div>
                  <div className="u-club-s-list__label">{`${t(
                    'rakeback'
                  )}:`}</div>
                  <div className="u-club-s-list__text-info">{rakeback}</div>
                </li>
              </ul>
              <div className="u-club-slider__rate">{price}</div>
            </div>
            <div className="u-club-slider__btn-w">
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="btn btn--yellow"
              >
                {t('join')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
